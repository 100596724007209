<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      :label-cols-md="(label == '') ? 0:4"
      :label="label"
      :description="desc"
    >
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <b-form-checkbox-group
          v-model="selected"
          :options="options"
          class="mb-3"
          value-field="id"
          text-field="name"
          disabled-field="disabled"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormCheckboxGroup, BFormGroup} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
  export default {
    components: {
      BFormCheckboxGroup,
      BFormGroup,
      ValidationProvider,
    },
    props: {
      value: {
        type: {},
        default: ()=>{}
      },
      label: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        default: ()=>[]
      },
    },
    data() {
      return {
        selected: this.value
      }
    },
    watch:{
      selected() {
        this.$emit('input', this.selected)
      },
    },
    mounted(){
      this.selected = this.value
    }
  }
</script>

<style lang="scss" scoped>

</style>