<style>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #000 !important;
  background-color: #000 !important;
}
</style>
<template>
  <div>
    <b-card>
      <form-checkbox
        v-if="renderComp"
        v-model="levels"
        :options="[
          { id: '1', name: 'Region' },
          { id: '2', name: 'Area Layanan' },
          { id: '3', name: 'Unit Layanan Pelanggan' },
          { id: '4', name: 'Kantor Jaga' },
        ]"
        @input="testing"
      />
      <div style="height: 400px">
        <LMap
          id="map"
          :center="center"
          :zoom="zoom"
          style="height: 80%; width: 100%"
          @update:bounds="boundsUpdated"
          @ready="ready"
        >
          <LTileLayer :url="url" />
          <LMarker
            v-for="item in markers"
            :lat-lng="[item.latitude, item.longitude]"
            :key="item.id"
          >
            <LPopup>
              {{ item.name }}
            </LPopup>
          </LMarker>
        </LMap>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
import mapboxgl from 'mapbox-gl'
import FormCheckbox from '@/views/base/form/FormCheckbox.vue'
import 'mapbox-gl-leaflet'
import 'mapbox-gl/dist/mapbox-gl.css'
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'
import _ from 'lodash'
window.mapboxgl = mapboxgl

export default {
  name: 'Map',
  components: {
    LPopup,
    LMap,
    LTileLayer,
    LMarker,
    FormCheckbox,
    BCard,
  },
  data() {
    return {
      center: [-6.668, 108.83],
      markerLatLng: [-6.668, 108.83],
      markers: [],
      dataRegion: null,
      renderComp: true,
      params: {},
      bounds: {},
      levels: [],
      zoom: 6,
      url:
        'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibmF1ZmFsYWtoeWFyNDYiLCJhIjoiY2t0ZWg4eTdhMnBwaDMycWs0YmVkZnU2MyJ9.bQ9yvRj3GVq1XiBWSekHsQ&zoomwheel=true&fresh=true#12/48.8665/2.3176',
    }
  },
  methods: {
    loadData() {
      const { bounds, levels } = this
      this.params['bounds[lat1]'] = bounds._northEast.lat
      this.params['bounds[lat2]'] = bounds._southWest.lat
      this.params['bounds[lng1]'] = bounds._northEast.lng
      this.params['bounds[lng2]'] = bounds._southWest.lng
      this.$http
        .get('/branch/map', {
          params: {
            'bounds[lat1]': bounds._northEast.lat,
            'bounds[lat2]': bounds._southWest.lat,
            'bounds[lng1]': bounds._northEast.lng,
            'bounds[lng2]': bounds._southWest.lng,
            levels,
          },
        })
        .then(res => {
          this.markers = res.data.data
        })
      this.renderComp = false
      this.$nextTick().then(() => {
        this.renderComp = true
      })
    },
    boundsUpdated: _.throttle(function (bounds) {
      this.bounds = bounds
      this.loadData(bounds)
    }, 5000),
    ready(map) {
      this.bounds = map.getBounds()
      this.loadData()
    },
    testing(data) {
      this.loadData()
    },
  },
}
</script>
